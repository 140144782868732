var base = require("../components/product/base");
var peTracking = require("../tracking/pushEngageTrackingEvent");

/**
 *
 * @param {*} productid - Product Id
 * @param {*} link - PDP Url for the given product
 * @param {*} price - Price of the product including discounts
 * @param {*} alertprice - Actual price of the product (optional)
 * @param {*} availability - Availability (inStock/outOfStock) of the product (optional --> default is OOS)
 * @param {*} variantid - Variant product (optional)
 * @param {*} title - product name
 * @param {*} imageurl - logo/brand of site
 * @param {*} bigimageurl - image url of the product
 */
function pushBackInStockAlert(productid, link, price, alertprice, availability, variantid, title, imageurl, bigimageurl) {
    try {
        //alert expiry time current time + 30 days
        var currentdate = new Date();
        currentdate.setDate(currentdate.getDate() + 30);
        var expiryisodate = currentdate.toISOString();
        var refObj = {
            productid:productid,
            link:link,
            price:price,
            alertprice:alertprice,
            expiryisodate:expiryisodate,
            availability:availability,
            variantid:variantid,
            title:title,
            imageurl:imageurl,
            bigimageurl:bigimageurl
        };
        peTracking.pushEngageEvent("BACK_IN_STOCK_ALERT", refObj);
    } catch (e) {
        console.log("issue while triggering Back In Stock push engage event "+ e);
    }
}

function getValuesAndPushBISAlert(ctrl) {
    try {
        var pid = ctrl.data("pid");
        var pname = ctrl.data("product-name");
        var link = ctrl.data("pdp-url");
        var actualprice = ctrl.data("sales-price");
        var pprice = ctrl.data("list-price");
        var imageurl = $(".brand-logo").find(".content-asset img")[0] ? $(".brand-logo").find(".content-asset img")[0].currentSrc : "";
        var bigimageurl = ctrl.data("image-url");
        var availability = "outOfStock";
        pushBackInStockAlert(pid, link, pprice, actualprice, availability, pid, pname, imageurl, bigimageurl);
    } catch (e) {
        console.log("issue while either getting pe fields from notifyme control or triggering Back In Stock push engage event "+ e);
    }    
}

var parent = "";
function notifymeModal(c, a, data) {
    if (c.hasClass("notifyme-attr")) {
        parent = c.parent();
    }
    if (a == "show") {
        $("#notifyMePopup").modal("show");
        $("#notify-logout").attr("data-pid", data.product.id);
    } else {
        $("#notifyMePopup").modal("hide");
        if (parent != "") {
            parent.find(".notifyme-attr").addClass("d-none");
            parent.find(".check-mark-attr").removeClass("d-none");
        }
    }
}

module.exports = {

    notifyMeInit: function () {
        $(document).off("click", ".notifyme-attr").on("click", ".notifyme-attr", function (e) {
            e.preventDefault();
            e.stopPropagation();
            var className = $(this);
            var buttonClass = $(this).closest("button")[0];
            var notifyme = $(this).closest("button");
            var dataUrls = buttonClass.dataset.url;
            $.ajax({
                url: dataUrls,
                method: "GET",
                success: function (data) {
                    var notifyUrl = resources.urls.notifyUrl;
                    var pid = data.product.id;
                    var form = {
                        pid: pid
                    };
                    var pname = data.product.productName;
                    var actualprice = data.product.price.sales.decimalPrice;
                    var pprice = data.product.price.list ? data.product.price.list.decimalPrice : actualprice;
                    var link = window.location.origin + "/" + pid + ".html";                    
                    var imageurl = $(".brand-logo").find(".content-asset img")[0] ? $(".brand-logo").find(".content-asset img")[0].currentSrc : "";
                    var bigimageurl = data.product.images.small[0].absURL;
                    var availability = "outOfStock";
                    var peObj = {
                        pid: pid,
                        pname: pname,
                        actualprice: actualprice,
                        pprice: pprice,
                        link: link,
                        imageurl: imageurl,
                        bigimageurl: bigimageurl,
                        availability: availability
                    };
                    if (className.hasClass("login")) {
                        $.spinner().start();
                        // Add call to Notify me Custom Object
                        $.ajax({
                            url: notifyUrl,
                            method: "POST",
                            data: form,
                            success: function (data) {                                
                                notifyme.find(".notifyme-attr").addClass("d-none");
                                $(".varnotify-background").removeClass("d-none");
                                var classData = $("#notifyMePopup").siblings(".varnotify-background");
                                if (classData.length != 0) {
                                    classData.addClass("d-none");
                                }
                                if (data.success) {
                                    $(".notify-success-msg").html(data.statusMessage);
                                    notifyme.find(".check-mark-attr.d-none").removeClass("d-none");
                                    $(".varnotify-background .check-mark-success").removeClass("d-none");
                                    $(".check-mark-error").addClass("d-none");
                                    pushBackInStockAlert(pid, link, pprice, actualprice, availability, pid, pname, imageurl, bigimageurl);
                                } else {
                                    $(".notify-success-msg").text(data.statusMessage);
                                    notifyme.find(".check-mark-attr.d-none").removeClass("d-none");
                                    $(".varnotify-background .check-mark-success").addClass("d-none");
                                    $(".check-mark-error.d-none").removeClass("d-none");
                                    notifyme.find(".notifyme-attr").addClass("notify-me-error");
                                    $(".varnotify-background .check-mark-attr").removeClass("d-none");
                                }
                                
                                setTimeout (function () {
                                    $(".varnotify-background").addClass("d-none");
                                }, 5000);
                                $.spinner().stop();
                            },
                            error: function (err) {
                                console.log(err);
                                $.spinner().stop();
                            }
                        });
                    } else {
                        notifymeModal(className, "show", data);
                        var peObjJSONString = JSON.stringify(peObj);
                        $(".modal-notify-me").attr("data-productdetails", peObjJSONString);
                        $(".modal-notify-me").data("productdetails", peObjJSONString);
                    }
                }
            });
        });
    
        $("body").off("click", "button.livetv-submit-notifyme").on("click", "button.livetv-submit-notifyme", function () {
            var notifyme = $(this).closest("button");
            var peObjJSONString = $(notifyme).data("productdetails");
            var peObj;
            if ((typeof peObjJSONString === "string" || peObjJSONString instanceof String) && peObjJSONString != "") {
                peObj = JSON.parse(peObjJSONString);
            } else {
                peObj = peObjJSONString;
                peObjJSONString = JSON.stringify(peObj);
            }
            if ($("#authentication").val() == "true") {
                var notifyUrl = resources.urls.notifyUrl;
                var pid = $(this)[0].dataset.pid;
                var form = {
                    pid: pid
                };
                $.spinner().start();
                $.ajax({
                    url: notifyUrl,
                    method: "POST",
                    data: form,
                    success: function (data) {
                        if (data.success) {
                            $(".varnotify-background .check-mark-success").removeClass("d-none");
                            $(".notify-success-msg").empty().append(data.statusMessage);
                            $(".varnotify-background").removeClass("d-none");
                            $(".varnotify-background .check-mark-attr").removeClass("d-none");
                            $(".check-mark-error").addClass("d-none");
                            setTimeout (function () {
                                $(".varnotify-background").addClass("d-none");
                            }, 5000);
                            if (peObj && peObj.pid && peObj.pid == pid) {
                                pushBackInStockAlert(peObj.pid, peObj.link, peObj.pprice, peObj.actualprice, peObj.availability, peObj.pid, peObj.pname, peObj.imageurl, peObj.bigimageurl);
                            }
                        } else {
                            $(".notify-success-msg").text(data.statusMessage);
                            $(".varnotify-background .check-mark-success").addClass("d-none");
                            $(".varnotify-background .check-mark-attr").addClass("d-none");
                            $(".check-mark-error.d-none").removeClass("d-none");
                            $(".varnotify-background").removeClass("d-none");
                            $(".varnotify-background .check-mark-success").addClass("d-none");
                            setTimeout (function () {
                                $(".varnotify-background").addClass("d-none");
                            }, 5000);
                        }
                        $("#notify-logout.submit-notify-me").removeClass("d-none");
                        notifymeModal($(this), "hide", "");
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            } else {
                $("#notifyMePopup").modal("show");
                $(".modal-notify-me").attr("data-pid", $(this).attr("data-pid"));
                $(".modal-notify-me").attr("data-productdetails", peObjJSONString);
                $(".modal-notify-me").data("productdetails", peObjJSONString);
            }

        });

        $("body").off("click", "button.submit-notify-me").on("click", "button.submit-notify-me", function () {
            var form;
            var notifyUrl = resources.urls.notifyUrl;
            var notifyme = $(this).closest("button");
            var logout = $(this).closest(".logout-notify-wrapper").length != 0 ? true : false;
            var source = notifyme.data("source");
            var pid;
            
            var formValidation;
            
            if (base.getPidValue($(this))) {
                pid = base.getPidValue($(this)); 
            } else {
                pid = $(".submit-notify-me")[0].dataset.pid;
                
            }
            if (source === "wishlist") {
                pid = notifyme.data("pid");
            }
            if (logout) {
                var name =  $("#notify-name").val();
                var contactInfo =$("#notify-email").val();
                var emailReg = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                var phoneReg = /^\b[2-9]\d{2}[2-9]\d{2}\d{4}\b$/;
                name == "" ? $("#notify-name").addClass("is-invalid") : $("#notify-name").removeClass("is-invalid");
                contactInfo == "" ? $("#notify-email").addClass("is-invalid") : $("#notify-email").removeClass("is-invalid");
                name == "" ? $("#notify-name").siblings().html("Enter your name") : $("#notify-name").siblings().html("");
                contactInfo == "" ? $("#notify-email").siblings().html("Enter your email/phone") : $("#notify-email").siblings().html("");
                if (contactInfo != "" && !emailReg.test(contactInfo) && !phoneReg.test(contactInfo)) {
                    $("#notify-email").addClass("is-invalid");
                    $("#notify-email").siblings().html("Please enter a valid email/phone");
                }  
                
                if (name != "" && contactInfo != "" && (emailReg.test(contactInfo) || phoneReg.test(contactInfo))) {
                    formValidation = true;
                }
                
                form = {
                    pid: pid,
                    name: name,
                    contactInfo: contactInfo,
                    isEmail: emailReg.test(contactInfo) ? true : false                
                };
            } else {
                form = {
                    pid: pid
                };
                formValidation = true;
            }
            if (formValidation == true) {
                $.spinner().start();
                $.ajax({
                    url: notifyUrl,
                    method: "POST",
                    data: form,
                    pid: pid,
                    success: function (data) {
                        if (data.success) {                            
                            if (source === "wishlist" || source === "quickview") {
                                $(".varnotify-background .check-mark-success").removeClass("d-none");
                                $(".notify-success-msg")[0].html = (data.statusMessage);
                                notifyme.find(".notifyme-attr").addClass("d-none");
                                notifyme.find(".check-mark-attr.d-none").removeClass("d-none");
                                $(".submit-notify-me").siblings(".varnotify-background").removeClass("d-none");
                                $(".varnotify-background .check-mark-attr").removeClass("d-none");
                                $(".check-mark-error").addClass("d-none");
                                $('.greentick-wishlist-image[data-pid="' + pid + '"]').removeClass("d-none");
                                $('.submit-notify-me-wishlist[data-pid="' + pid + '"]').addClass("d-none");
                                setTimeout (function () {
                                    $(".varnotify-background").addClass("d-none");
                                }, 5000);
                            } else {
                                $(".submit-notify-me").addClass("d-none");
                                $(".add-to-wishlist-btn").addClass("d-none");
                                $(".remove-wishlist-btn").addClass("d-none");
                                $(".out-of-stock-label .out-of-stock-message").addClass("d-none");
                                $(".notify-me-btn-message").removeClass("d-none");
                                $(".notify-me-btn-message").addClass("d-flex");
                                $(".varnotify-background .check-mark-success").removeClass("d-none");
                                $(".check-mark").removeClass("d-none");
                                $(".logout-notify-wrapper").addClass("d-none");
                            }
                            getValuesAndPushBISAlert(notifyme);
                        } else {
                            if (source === "wishlist" || source === "quickview") {
                                notifyme.find(".notifyme-attr").addClass("d-none");
                                $(".notify-success-msg").text(data.statusMessage);
                                $(".varnotify-background .check-mark-success").addClass("d-none");
                                $(".varnotify-background .check-mark-attr").addClass("d-none");
                                $(".check-mark-error.d-none").removeClass("d-none");
                                notifyme.find(".notifyme-attr").addClass("notify-me-error");
                                $(".submit-notify-me").siblings(".varnotify-background").removeClass("d-none");
                                $(".varnotify-background .check-mark-success").addClass("d-none");
                                $('.greentick-wishlist-image[data-pid="' + pid + '"]').removeClass("d-none");
                                $('.submit-notify-me-wishlist[data-pid="' + pid + '"]').addClass("d-none");
                                setTimeout (function () {
                                    $(".varnotify-background").addClass("d-none");
                                }, 5000);
    
                            } else {
                                $(".submit-notify-me").addClass("d-none");
                                $(".add-to-wishlist-btn").addClass("d-none");
                                $(".remove-wishlist-btn").addClass("d-none");
                                $(".out-of-stock-label .out-of-stock-message").addClass("d-none");
                                $(".notify-success-msg").text(data.statusMessage);
                                $(".check-mark").addClass("d-none");
                                $(".check-mark-error.d-none").removeClass("d-none");
                                $(".varnotify-background .check-mark-success").addClass("d-none");
                                $(".notify-me-btn-message").addClass("notify-me-error");
                                $(".notify-me-btn-message").removeClass("d-none");
                                $(".notify-me-btn-message").addClass("d-flex");
                                $(".logout-notify-wrapper").addClass("d-none");
                            }
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });

        $("body").off("click", "button.submit-notify-me-sticky").on("click", "button.submit-notify-me-sticky", function () {
            var notifyme = $(this).closest("button");
            var peObj = {
                pid: $(notifyme).data("pid"),
                pname: $(notifyme).data("product-name"),
                actualprice: $(notifyme).data("sales-price"),
                pprice: $(notifyme).data("list-price"),
                link: $(notifyme).data("pdp-url"),
                imageurl: $(".brand-logo").find(".content-asset img")[0] ? $(".brand-logo").find(".content-asset img")[0].currentSrc : "",
                bigimageurl: $(notifyme).data("image-url"),
                availability: "outOfStock"
            };
            if ($(this).hasClass("login")) {
                var buttonClass = $(this).closest("button")[0];
                var dataUrls = buttonClass.dataset.url;                
                $.ajax({
                    url: dataUrls,
                    method: "GET",
                    success: function () {
                        var notifyUrl = resources.urls.notifyUrl;
                        var pid = $(".submit-notify-me-sticky")[0].dataset.pid;
                        var form = {
                            pid: pid
                        };
                        $.spinner().start();
                        // Add call to Notify me Custom Object
                        $.ajax({
                            url: notifyUrl,
                            method: "POST",
                            data: form,
                            success: function (data) {
                                if (data.success) {
                                    $(".varnotify-background .check-mark-success").removeClass("d-none");
                                    $(".notify-success-msg").empty().append(data.statusMessage);
                                    $(".varnotify-background").removeClass("d-none");
                                    $(".varnotify-background .check-mark-attr").removeClass("d-none");
                                    $(".check-mark-error").addClass("d-none");
                                    setTimeout (function () {
                                        $(".varnotify-background").addClass("d-none");
                                    }, 5000);
                                    getValuesAndPushBISAlert(notifyme);
                                } else {
                                    $(".notify-success-msg").text(data.statusMessage);
                                    $(".varnotify-background .check-mark-success").addClass("d-none");
                                    $(".varnotify-background .check-mark-attr").addClass("d-none");
                                    $(".check-mark-error.d-none").removeClass("d-none");
                                    $(".varnotify-background").removeClass("d-none");
                                    $(".varnotify-background .check-mark-success").addClass("d-none");
                                    setTimeout (function () {
                                        $(".varnotify-background").addClass("d-none");
                                    }, 5000);
                                }
                                $("#notify-logout.submit-notify-me").removeClass("d-none");
                                $("#notifyMePopup").modal("hide");
                                $.spinner().stop();
                            },
                            error: function (err) {
                                console.log(err);
                                $.spinner().stop();
                            }
                        });
                    }
                });       
            } else {
                $("#notifyMePopup").modal("show");
                $(".modal-notify-me").attr("data-pid", $(this).attr("data-pid"));
                var peObjJSONString = JSON.stringify(peObj);
                $(".modal-notify-me").attr("data-productdetails", peObjJSONString);
                $(".modal-notify-me").data("productdetails", peObjJSONString);
            }
        });

        $("body").off("click", "button.modal-notify-me").on("click", "button.modal-notify-me", function () {
            var name =  $("#notify-form-name").val();
            var contactInfo = $("#notify-form-email").val();
            var emailReg = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
            var phoneReg = /^\b[2-9]\d{2}[2-9]\d{2}\d{4}\b$/;
            name == "" ? $("#notify-form-name").addClass("is-invalid") : $("#notify-form-name").removeClass("is-invalid");
            contactInfo == "" ? $("#notify-form-email").addClass("is-invalid") : $("#notify-form-email").removeClass("is-invalid");
            name == "" ? $("#notify-form-name").siblings().html("Enter your name") : $("#notify-form-name").siblings().html("");
            contactInfo == "" ? $("#notify-form-email").siblings().html("Enter your email/phone") : $("#notify-form-email").siblings().html("");
            if (contactInfo != "" && !emailReg.test(contactInfo) && !phoneReg.test(contactInfo)) {
                $("#notify-form-email").addClass("is-invalid");
                $("#notify-form-email").siblings().html("Please enter a valid email/phone");
            }  
            if (name != "" && contactInfo != "" && (emailReg.test(contactInfo) || phoneReg.test(contactInfo))) {
                var notifyUrl = resources.urls.notifyUrl;
                var pid = $(".modal-notify-me")[0].dataset.pid;
                var form = {
                    pid: pid,
                    name: name,
                    contactInfo: contactInfo,
                    isEmail: emailReg.test(contactInfo) ? true : false
                };
                var peObjJSONString = $(".modal-notify-me")[0].dataset.productdetails;
                var peObj;
                if ((typeof peObjJSONString === "string" || peObjJSONString instanceof String) && peObjJSONString != "") {
                    peObj = JSON.parse(peObjJSONString);
                } else {
                    peObj = peObjJSONString;
                }
                $.spinner().start();
                $.ajax({
                    url: notifyUrl,
                    method: "POST",
                    data: form,
                    success: function (data) {
                        var classData = $("#notifyMePopup").siblings(".varnotify-background");
                        if (data.success) {
                            if (classData.length != 0) {
                                classData.removeClass("d-none");
                            } else {
                                $(".varnotify-background").removeClass("d-none");
                            }
                            $(".varnotify-background .check-mark-success").removeClass("d-none");
                            $(".notify-success-msg").empty().append(data.statusMessage);
                            $(".varnotify-background .check-mark-attr").removeClass("d-none");
                            $(".check-mark-error").addClass("d-none");
                            setTimeout (function () {
                                $(".varnotify-background").addClass("d-none");
                            }, 5000);
                            if (peObj && peObj.pid && peObj.pid == pid) {
                                pushBackInStockAlert(peObj.pid, peObj.link, peObj.pprice, peObj.actualprice, peObj.availability, peObj.pid, peObj.pname, peObj.imageurl, peObj.bigimageurl);
                            }
                        } else {
                            if (classData.length != 0) {
                                classData.removeClass("d-none");
                            } else {
                                $(".varnotify-background").removeClass("d-none");
                            }
                            $(".notify-success-msg").text(data.statusMessage);
                            $(".varnotify-background .check-mark-success").addClass("d-none");
                            $(".varnotify-background .check-mark-attr").addClass("d-none");
                            $(".check-mark-error.d-none").removeClass("d-none");
                            $(".varnotify-background .check-mark-success").addClass("d-none");
                            setTimeout (function () {
                                $(".varnotify-background").addClass("d-none");
                            }, 5000);
                        }
                        $("#notify-logout.submit-notify-me").removeClass("d-none");
                        notifymeModal($(this), "hide", "");
                        $.spinner().stop();
                        $("#notify-form-name").val("");
                        $("#notify-form-email").val("");
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    }
};
